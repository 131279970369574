import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { IAppConfig, ICountryConfig, UserRo } from "interfaces";
import { useAuth, useDisclosures, useEventListener, useIovationBlackbox, usePartialState, useSelector } from "hooks";
import { useGetAppConfigQuery, useGetLatestHistoryQuery, useGetUserCountryAppConfigQuery } from "apis";
import { FeatureUnavailableModal } from "ui";
import { useInstallWebengage, WebEngage } from "hooks/useWebEngage";
import { AuthPayload, selectAuth } from "store/slices/auth";
import { useWishlistCheck } from "./modals.context";
import { orderBy } from "lodash";
import ls from "utils/secureStorage";
import { useDisclosure } from "@chakra-ui/react";
import { selectUser } from "store/slices";
import { useCountryFullInfo } from "hooks/useCurrency";

// import { useControls } from "leva";

type ModalTypes = "featureUnavailable";
interface IAppConfigContext extends ReturnType<typeof useDisclosures<ModalTypes>> {
  isLoading: boolean;
  appfigs: Partial<IAppConfig>;
  countryfigs: Partial<ICountryConfig>;
  set: (update: Partial<IAppConfig>) => void;
  getSupportedCoins: (name: keyof IAppConfig) => string[];
  refetch: () => void;
  refetchLocaleFigs: () => void;
  webengage?: WebEngage;

  // This is just for convenience
  auth?: AuthPayload;
  hasWonButNotSeen?: boolean;
  wishlist?: ReturnType<typeof useWishlistCheck>;
  gameCheck: ReturnType<typeof useGamificationUpdateCheck>;
  profile?: UserRo;
  getBlackbox: () => string | null;
  its_christmas?: boolean;
}

const AppConfigContext = createContext<IAppConfigContext | null>(null);

export function AppConfigProvider(props: PropsWithChildren<any>) {
  const { children } = props;

  const { name: country } = useCountryFullInfo();

  // const isInitialSet = useRef(false);
  const { auth } = useAuth();
  const { profile } = useSelector(selectUser);
  const { webengage } = useInstallWebengage();
  const { data: appfigs, isLoading, refetch } = useGetAppConfigQuery(null /*, { skip: !auth?.isSignedIn }*/);

  const { getBlackbox } = useIovationBlackbox();

  const {
    data: localefigs,
    isLoading: isLoadingLocalFigs,
    refetch: refetchLocaleFigs,
  } = useGetUserCountryAppConfigQuery(null, { skip: !auth?.isSignedIn });
  const countryfigs = Reflect.get(localefigs?.country ?? {}, country);

  const wishlist = useWishlistCheck(true ?? !auth?.isSignedIn);
  const gameCheck = useGamificationUpdateCheck();
  const [, set] = usePartialState<IAppConfig>({});

  console.log("WebEngage", webengage);
  console.log("App Configs", appfigs);
  console.log("Country Configs", countryfigs);
  console.log("AUTH", auth);

  const disclosableValues = useDisclosures<ModalTypes>();
  const { isOpen, close } = disclosableValues;

  const getSupportedCoins = (configName: keyof IAppConfig) => [];

  const its_christmas = new Date().getMonth() === 11;

  // console.log("App Config::Context", appfigs);

  return (
    <AppConfigContext.Provider
      value={{
        appfigs: appfigs ?? {},
        countryfigs: countryfigs ?? {},
        set,
        webengage,
        isLoading: isLoading || isLoadingLocalFigs,
        getSupportedCoins,
        refetch,
        refetchLocaleFigs,
        auth,
        hasWonButNotSeen: wishlist.hasWonButNotSeen,
        wishlist,
        gameCheck,
        profile,
        getBlackbox,
        its_christmas,
        ...disclosableValues,
      }}
    >
      {children}

      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </AppConfigContext.Provider>
  );
}

export function useAppConfig() {
  const context = useContext(AppConfigContext);
  if (!context) throw new Error("useAppConfig must be used within an AppConfigContext");
  return context;
}

interface IUseGamificationUpdateCheck {
  onOpen: () => void;
}
export function useGamificationUpdateCheck(args?: IUseGamificationUpdateCheck) {
  const { payload } = useSelector(selectAuth);
  const prev_data_id = useRef<string>("none");

  const { isOpen: is_gc_open, onOpen, onClose: on_gc_close } = useDisclosure();

  const on_gc_open = useCallback(() => (args?.onOpen ?? onOpen)(), [args?.onOpen, onOpen]);

  console.log("Auth payload", payload);

  // const { data, refetch } = useGetHistoryQuery("all", {
  //   pollingInterval: 5000,
  //   skip: !payload?.isSignedIn,
  // });

  const { data: latest, refetch } = useGetLatestHistoryQuery("", {
    // pollingInterval: 5000,
    skip: !payload?.isSignedIn,
  });
  console.log("Latest badges", orderBy(latest?.data, ["updatedAt"], ["desc"]));

  const last_achievement = useMemo(() => orderBy(latest?.data, ["updatedAt"], ["desc"])[0], [latest]);

  const GAME_AWARD_KEY = "last_game_award_id";
  useEffect(() => {
    const last_propagated_id = ls.get(GAME_AWARD_KEY);
    console.log("History from modal.context[before]", { last_propagated_id, last_achievement });

    if (!!last_achievement && prev_data_id.current !== last_achievement?._id && last_propagated_id !== last_achievement?._id) {
      console.log("History from modal.context", last_achievement);
      prev_data_id.current = last_achievement?._id;
      ls.set(GAME_AWARD_KEY, last_achievement?._id);
      // onOpen("new_earned_badge_update");
      on_gc_open();
    }

    const timeout_id = setTimeout(() => {
      if (prev_data_id.current !== "none") prev_data_id.current = "none";
    }, 8000);

    return () => clearTimeout(timeout_id);
  }, [prev_data_id, last_achievement, on_gc_open]);

  // const {hasWonButNotSeen} = useWishlistCheck()

  useEventListener("refetch_badge_earned", (data: any) => {
    console.log("Earn a new badge", data);
    refetch();
    // setLastAchievement(data?.metadata);

    // window.alert("Earned a new badge");
  });

  return { last_achievement, is_gc_open, on_gc_open, on_gc_close };
}
