import {
  Box,
  Button,
  Divider,
  FormControl,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import BitcoinPNG from "assets/images/bitcoin.png";
import { Group, Icon, InputLabel, Link, Logo, ShowCustomerSupport, Version } from "components";
import { useEffect, useMemo, useRef } from "react";
import Recaptcha from "react-google-recaptcha";

import { navigate, useLocation } from "@reach/router";
import Input from "components/Input/Input";
import PasswordInput from "components/Input/PasswordInput";
import configs, { isProd } from "config";
import { useDispatch, usePartialState, useSelector } from "hooks";
import { LoginDto } from "interfaces";
import { isEmpty } from "lodash";
import { login, selectAuth } from "store/slices";
import { AuthImage, PolicyTerms } from "ui";
import { when } from "utils";
import { isSafeRedirect } from "utils/urls";
import { IntercomProvider } from "react-use-intercom";

interface ILoginState extends LoginDto {}

export default function Login(props: { path: string }) {
  const recaptchaRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { status, payload } = useSelector(selectAuth);
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [state, set] = usePartialState<ILoginState>({});

  const hColor = useColorModeValue("#303131", "white");
  const pColor = useColorModeValue("grey.500", "grey.150");

  const dividerColor = useColorModeValue("grey.300", "grey.800");
  const logoColor = useColorModeValue("primary.default", "secondary.500");
  const loginColor = useColorModeValue("primary.default", "secondary.500");

  const isLoading = useMemo(() => status === "logging_in", [status]);

  // console.log()

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const captcha = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();

    dispatch(login({ ...state, humanKey: captcha } as LoginDto));
  };

  const isDisabled = useMemo(() => !(state?.email && state?.password) || isLoading, [state, isLoading]);

  useEffect(() => {
    const rto = params.get("rto");
    const isSafeRto = isSafeRedirect({ url: rto!, skip: !isProd });
    if (payload?.isSignedIn && payload?.token) {
      const path = when(!params.has("rto"), configs.paths.dashboard, when(isSafeRto, rto!, configs.paths.dashboard));
      (window.location as any) = path;
      console.log("Login rto path", path);
      // navigate(configs.paths.dashboard);
      // webengage?.user.login(payload.)
    }

    if (!!payload?.token && !isEmpty(payload?.verification ?? [])) {
      let path = configs.paths.verify;
      if (params.has("rto") && isSafeRto) path.concat(`?rto=${rto!}`);
      navigate(path);
    }

    // eslint-disable-next-line
  }, [payload, params]);

  const [isLargerThan1080] = useMediaQuery("(min-width: 1080px)");

  const [isMobile] = useMediaQuery("(max-width: 1023px)");

  return (
    <IntercomProvider appId={configs.INTERCOM_TOKEN}>
      <Box
        minH="100vh"
        pos="relative"
        sx={{
          "--w": isLargerThan1080 ? "580px" : "480px",
        }}
      >
        <AuthImage isMobile={isMobile} />

        <Box pos="relative" w={isMobile ? "100%" : "calc(100% - var(--w))"} ml="auto">
          <Group maxWidth="700px" margin="auto" padding={{ sm: "32px 20px 43px", "2sm": "80px 20px 43px" }}>
            <Logo
              fontSize="54px"
              mb={{ sm: "12px", "2sm": "52px" }}
              _name={{ w: "110px" }}
              _cap={{ top: "-4px", left: "0px" }}
              color={logoColor}
            />

            <Box mb="32px !important">
              <Heading fontSize={{ sm: "24px", "2sm": "32px" }} color={hColor} display="flex" alignItems="center">
                Hello! We’re glad to have you back
                <Box as="img" boxSize="48px" src={BitcoinPNG} />
              </Heading>

              <Text color={pColor} fontSize="18px" mt="14px">
                Log In to pick up from where you stopped.
              </Text>
            </Box>

            <Stack as="form" maxW="540px" gridGap="24px" onSubmit={handleSubmit}>
              <FormControl>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                  isRequired
                  type="email"
                  id="email"
                  placeholder="Enter Email"
                  value={state?.email ?? ""}
                  onChange={(e) => set({ email: e.target.value })}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="password">Password</InputLabel>
                <PasswordInput
                  id="password"
                  isRequired
                  placeholder="Enter Password"
                  value={state?.password ?? ""}
                  onChange={(e) => set({ password: e.target.value })}
                />
              </FormControl>

              <VStack mt="-10px !important" mb="66px !important">
                <HStack w="100%" justifyContent="flex-end" mb="58px">
                  <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />
                  <Link to="/forgot" color={pColor} textDecoration="underline">
                    Forgot Password
                  </Link>
                </HStack>

                <Button
                  type="submit"
                  minW="initial"
                  maxW="399px"
                  width="100%"
                  rightIcon={<Icon type="arrowRight" color="white" />}
                  isLoading={isLoading}
                  disabled={isDisabled}
                  _hover={{
                    _disabled: {
                      opacity: 0.4,
                    },
                  }}
                >
                  Login
                </Button>
              </VStack>

              <Divider borderColor={dividerColor} />

              <VStack mt="0 !important">
                {/* <Text fontSize="14px" color={pColor}>
                OR
              </Text> */}

                {/* <GoogleButton mt="14px !important" /> */}

                <HStack fontWeight="600" mt="34px !important">
                  <Text fontWeight="500" color={pColor}>
                    Not You?{" "}
                  </Text>
                  <Link ml="14px !important" to="/signup" color={loginColor} textDecoration="underline">
                    Sign Up
                  </Link>
                </HStack>

                <PolicyTerms />

                <Version my="20px !important" />
              </VStack>
            </Stack>
          </Group>
        </Box>

        <ShowCustomerSupport />
      </Box>
    </IntercomProvider>
  );
}
