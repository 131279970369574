import { useState, useMemo, useRef, useEffect } from "react";
import { navigate } from "@reach/router";
import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  useToast,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Icon,
  InputLabel,
  useModalView,
  PasswordInput,
  PinInput,
  Steps,
  ConditionalRender,
  ToggleSmsOrCallChannel,
  SendViaWhatsapp,
  SendOTP,
} from "components";
import { usePartialState, useRequestVerificationCode } from "hooks";
import { useRequestResetVerificationCodeMutation, useResetTransactionPinMutation } from "apis";
// import { AnimatePresence, motion } from "framer-motion";
// import { when } from "utils";
import Recaptcha from "react-google-recaptcha";
import configs from "config";

// import ls from "utils/secureStorage";

interface IState {
  trial: number;
  delivery: "sms" | "call";
  pin: string;
  initialTime: number;
  timerStarted: boolean;
}
const PIN_RESET_CACHE_KEY = "prtck";
// type TimerCacheType = { time: number; date: number };

export default function ResetTransaction(props: any) {
  const { setCurrentView } = useModalView();
  const toast = useToast();

  // useEffect(() => {
  //   return () => {
  //     setCurrentView(0);
  //   };
  // }, [setCurrentView]);
  const resumed_countdown = useRef(false);

  const [state, set] = usePartialState<IState>({
    // delivery: "sms",
    trial: 0,
  });

  // const { startCountdown, toTimeString, timedout, started } = usePersistentCountdown(PIN_RESET_CACHE_KEY);

  const useRequestCode = useRequestVerificationCode({
    defaultChannel: "sms",
    timerKey: PIN_RESET_CACHE_KEY,
  });

  useEffect(() => {
    if (useRequestCode?.started && !resumed_countdown.current) {
      set({ trial: (state?.trial ?? 0) + 1 });
      resumed_countdown.current = true;
    }
  }, [useRequestCode?.started, set, state?.trial]);

  return (
    <Steps hideIndicator>
      <Index
        set={set}
        state={state}
        toast={toast}
        goBack={() => setCurrentView(0)}
        // startCountdown={startCountdown}
        // toTimeString={toTimeString}
        // timedout={timedout}
        // countDownStarted={started}
        useRequestCode={useRequestCode}
      />
      <ResetTransactionPinForm toast={toast} resetCode={state.pin!} />
    </Steps>
  );
}

interface IndexProps {
  goBack: () => void;
  toast: any;
  set: (update: Partial<IState>) => void;
  state: Partial<IState>;
  // startCountdown: (secs: number) => void;
  // toTimeString: () => string;
  // timedout: boolean;
  // countDownStarted: boolean;
  useRequestCode: ReturnType<typeof useRequestVerificationCode>;
}

function Index(props: IndexProps) {
  const { setCurrentView } = useModalView();
  const { goBack, toast, set, state, useRequestCode } = props;
  // const { profile } = useSelector((state) => state.user);
  const recaptchaRef = useRef<any>(null);

  // const initialTimeRef = useRef<any>();

  const { trial = 0 } = state;

  // const color = useColor();
  const tColor = useColorModeValue("grey.400", "grey.100");
  const t2Color = useColorModeValue("grey.500", "grey.150");

  const isDisabled = useMemo(() => !state?.pin || (!!state?.pin && state.pin.length < 6), [state]);

  const [_requestPinVerificationCode, { isLoading, isSuccess }] = useRequestResetVerificationCodeMutation();

  const { channel, switchChannel, isChannelAvailable, started: countDownStarted, startCountdown, timedout } = useRequestCode || {};

  // const toggleDelivery = (e: any) => {
  //   e?.preventDefault();
  //   if (channel === "call") switchChannel("sms");
  //   else switchChannel("call");
  // };

  // const toggleWhatsappDelivery = async () => {
  //   switchChannel("whatsapp");
  //   await handleRequest("whatsapp");
  // };

  // const btnLabel = useMemo(() => (channel === "call" ? "Dial" : "Send Code"), [channel]);

  const buttonLabel = useMemo(() => {
    if (trial < 1 && channel === "sms") return "send code";
    else if (trial < 1 && channel === "call") return "dial";
    else if (trial > 0 && channel === "sms") return "resend code";
    else if (trial > 0 && channel === "call") return "redial";
    else return "send code";
  }, [trial, channel]);

  const handleRequest = async (delivery?: string) => {
    try {
      const response: any = await _requestPinVerificationCode({ channel: delivery ?? channel, call: channel === "call" });

      if (response.data) {
        set({ trial: trial + 1, initialTime: 600, timerStarted: true });
        startCountdown(600);
        toast({
          position: "bottom-right",
          title: "Success",
          description: response?.data?.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      // setHasResetPin(false);

      toast({
        position: "bottom-right",
        title: "Error",
        description: error?.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p="6px">
      <HStack>
        <IconButton
          size="xs"
          minW="fit-content"
          maxW="fit-content"
          minH="fit-content"
          maxH="fit-content"
          p="0 !important"
          bg="transparent"
          variant="transparent"
          aria-label="back button"
          icon={<Icon boxSize="20px" type="circleLeftArrow" />}
          onClick={goBack}
        />

        <Heading as="h6" fontSize="16px" color="secondary.400">
          Reset Transaction Pin
        </Heading>
      </HStack>

      <Box mt="22px">
        <Recaptcha ref={recaptchaRef} sitekey={configs.RECAPTCHA_KEY} size={"invisible"} />

        <Box mb="48px">
          <ConditionalRender shouldRender={trial > 0 || countDownStarted}>
            <Text fontSize="14px" fontWeight="600" color={t2Color}>
              Enter the OTP you received via your phone number
            </Text>
          </ConditionalRender>
          <Text fontSize="14px" fontWeight="500" color={tColor}>
            To be sure that this is you, we need to send you an OTP Code
          </Text>
        </Box>

        <ConditionalRender shouldRender={trial > 0 || countDownStarted}>
          <PinInput
            size="sm"
            onChange={(pin) => set({ pin })}
            onComplete={(pin) => {
              set({ pin });
              setCurrentView(1);
            }}
            _field={{ maxW: "38px", borderStyle: "solid" }}
            _container={{ justifyContent: "flex-start", gridGap: "18px" }}
          />
        </ConditionalRender>

        <HStack mt="46px">
          {/* <Button
            variant="max"
            p="10px"
            minW="fit-content"
            isLoading={isLoading}
            disabled={isLoading || isSuccess || !timedout}
            // onClick={handleRequest}
          >
            {!timerStarted ? buttonLabel : <Text ref={initialTimeRef}>{convertNumberMS(String(initialTime))}</Text>}
            {timedout ? buttonLabel : <Text ref={initialTimeRef}>{toTimeString()}</Text>}
          </Button> */}

          <SendOTP
            isLoading={isLoading}
            timeStart={0}
            timerKey={PIN_RESET_CACHE_KEY}
            channel={channel}
            sendOtp={() => handleRequest(channel)}
            isDisabled={isLoading || isSuccess || !timedout}
            useRequestCode={useRequestCode}
            label={buttonLabel}
          />
        </HStack>

        <HStack>
          {/* <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div
              key={`delivery-${when(channel === "sms", "sms", "call")}`}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}
            >
              <HStack
                fontSize="14px"
                fontWeight="600"
                mt="10px !important"
                as="a"
                href="#"
                color={color("primary.default", "secondary.400")}
                onClick={toggleDelivery}
                textDecoration="underline"
                minH="28px"
              >
                {channel === "sms" && (
                  <>
                    <Icon type="calling" color={color("primary.default", "secondary.400")} />
                    <Text>Call me via phone instead</Text>
                  </>
                )}

                {channel === "call" && <Text>Send code via sms.</Text>}
              </HStack>
            </motion.div>
          </AnimatePresence> */}

          {/* {isChannelAvailable(["whatsapp"]) && (
            <HStack
              fontSize="14px"
              fontWeight="600"
              mt="10px !important"
              as="a"
              href="#"
              color={color("primary.default", "secondary.400")}
              onClick={toggleWhatsappDelivery}
              textDecoration="underline"
              minH="28px"
              // pointerEvents={when(!timedout && started, "none", "auto")}
              // opacity={when(!timedout && started, ".4", "1")}
              // cursor={when(!timedout && started, "no-drop", "pointer")}
            >
              <>
                <Icon type="whatsappOutline" color={color("primary.default", "secondary.400")} boxSize="20px" />
                <Text>Send via Whatsapp</Text>
              </>
            </HStack>
          )} */}

          <ToggleSmsOrCallChannel
            channel={channel ?? "none"}
            setChannel={switchChannel}
            isSmsOrCallChannelAvailable={isChannelAvailable(["call", "sms"])}
          />

          <SendViaWhatsapp
            channel={channel ?? "none"}
            setChannel={switchChannel}
            isWhatsappChannelAvailable={isChannelAvailable(["whatsapp"])}
            sendOtp={(s) => handleRequest(channel)}
          />
        </HStack>
      </Box>

      <VStack my="100px">
        <Button
          fontFamily="var(--bitmama-fonts-heading)"
          disabled={isDisabled || isLoading}
          onClick={() => setCurrentView(1)}
          minW={{ base: "100%", "4sm": "400px" }}
        >
          Next
        </Button>
      </VStack>
    </Box>
  );
}

interface ResetFormProps {
  toast: any;
  resetCode: string;
}

function ResetTransactionPinForm(props: ResetFormProps) {
  const { setCurrentView } = useModalView();

  const { toast, resetCode } = props;

  const [state, setState] = useState({
    newConfirmPin: "",
    newPin: "",
  });

  const pinMatch = state.newConfirmPin === state.newPin;

  const [_resetTransactionPin, { isLoading }] = useResetTransactionPinMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const makeRequest = async (code?: string) => {
    const { newConfirmPin, newPin } = state;

    try {
      const response: any = await _resetTransactionPin({
        newConfirmPin,
        newPin,
        resetCode: code ?? resetCode,
      });

      if (response.data) {
        toast({
          position: "bottom-right",
          title: "Success",
          description: response?.data?.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        navigate("/account/profile/pin/#reset");
      }
    } catch (error: any) {
      console.log(error, "error");
      toast({
        position: "bottom-right",
        title: "Error",
        description: error?.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    await makeRequest();
  };

  // useEffect(() => {
  //   return () => {
  //     setCurrentView(0);
  //   };
  // }, [setCurrentView]);

  return (
    <Box>
      <HStack mb="46px">
        <IconButton
          size="xs"
          minW="fit-content"
          maxW="fit-content"
          minH="fit-content"
          maxH="fit-content"
          p="0 !important"
          bg="transparent"
          variant="transparent"
          aria-label="back button"
          icon={<Icon boxSize="20px" type="circleLeftArrow" />}
          onClick={() => setCurrentView(0)}
        />
        <Heading as="h6" fontSize="sm" color="secondary.400">
          Reset Transaction Pin
        </Heading>
      </HStack>

      <Stack as="form" onSubmit={handleSubmit}>
        <FormControl mb="20px !important">
          <InputLabel htmlFor="newPin">New Pin</InputLabel>
          <PasswordInput isRequired id="newPin" name="newPin" onChange={handleChange} />
        </FormControl>

        <FormControl mb="20px !important">
          <InputLabel htmlFor="newConfirmPin">Confirm Pin</InputLabel>
          <PasswordInput isRequired id="newConfirmPin" name="newConfirmPin" onChange={handleChange} />

          {!pinMatch && state.newConfirmPin.length >= 3 && (
            <Text color="error" fontSize="14px">
              Pins do not match
            </Text>
          )}
        </FormControl>

        <VStack mt="40px !important">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!(state.newConfirmPin.length === 6) || !(state.newPin.length === 6) || isLoading || !pinMatch}
            minW={{ base: "100%", "4sm": "400px" }}
          >
            Update
          </Button>
        </VStack>
      </Stack>
    </Box>
  );
}
