import { Box, BoxProps, Button, Grid, Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useSwapMutation } from "apis";
import { AbstractModal, useAbstractModal } from "components";
import configs from "config";

import { useEffect, useMemo } from "react";
import { toCoinLocale, toPrecision } from "utils";
import { IState } from "../Panels/New";
import { Emitter } from "libs";
import { AppAnalyticEvent } from "interfaces";
import { useAppConfig } from "contexts/appconfig.context";
import { usePartialState } from "hooks";

import ls from "utils/secureStorage";
import { PairSwapRo } from "interfaces/swap.interface";

interface SwapPreviewProps {
  state: Partial<IState>;
  rate: number;
  isOpen: boolean;
  onClose: () => void;
  reset: NonNullable<ReturnType<typeof usePartialState<Partial<IState>>>[2]>;
  swapFee: string;

  previewData?: PairSwapRo;
}

interface ItemProps extends BoxProps {
  info: string;
  description: string;
}

function Preview(props: SwapPreviewProps) {
  const { previewData, state, onClose, reset } = props;

  const { appfigs } = useAppConfig();
  const { updateModal } = useAbstractModal();
  const { swapConfig } = appfigs ?? {};

  const bcolor = useColorModeValue("#B7DFA280", "#B7DFA220");

  const [_swapPair, { data: swapData, isLoading: isSwaping, isSuccess, isError, reset: resetApiState }] = useSwapMutation();

  const swapPair = async () => {
    const reqBody = {
      swapRate: state?.rate,
      destToken: state?.toCoin,
      sourceToken: state?.fromCoin,
      sourceAmount: state?.fromAmount,
      destAmount: state?.toAmount,
    };

    // console.log("REQ DATA", reqBody);
    Emitter.emit(AppAnalyticEvent.SWAP_INITIATED, reqBody);

    const result = await _swapPair(reqBody).unwrap();
    if (!!result) Emitter.emit(AppAnalyticEvent.SWAP_SUBMITTED, reqBody);
  };

  const swapFeeValue = useMemo(
    () => previewData?.fee ?? +(state?.toAmount ?? 0) * (configs.swap.fee / 100),
    [state?.toAmount, previewData]
  );
  const finalQuantity = useMemo(() => +(state?.toAmount ?? 0) - +(swapFeeValue ?? 0), [state?.toAmount, swapFeeValue]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await swapPair();
  };

  console.log("Swap Config", { swapConfig, previewData, swapFeeValue });

  useEffect(() => {
    const close = () => {
      reset(["toAmount", "fromAmount"]);
      onClose();
      updateModal({ isSuccess: false });
      ls.set(configs.LAST_SWAP_TOKENS, { from: state?.fromCoin, to: state?.toCoin });
    };

    if (isSuccess && !isError && swapData) {
      updateModal &&
        updateModal({
          isSuccess: true,
          success: {
            description: `${toPrecision(
              swapData?.destinationAmount ?? finalQuantity,
              state?.toCoin ?? "btc",
              false
            )} has been deposited into your ${toCoinLocale(state?.toCoin ?? "btc")} Wallet`,
            actions: [
              {
                label: "Close",
                onClick: () => {
                  close();
                },
              },
            ],
          },
        });

      // reset(["toAmount", "fromAmount"]);
    }

    return () => {
      resetApiState();
    };
  }, [isSuccess, isError, swapData, reset, resetApiState, state, finalQuantity, onClose, updateModal]);

  return (
    <VStack
      as="form"
      p={{ base: "95px 20px", "1sm": "45px 40px", "2sm": "45px 100px" }}
      minH={{ base: "100vh", "1sm": "initial" }}
      onSubmit={handleSubmit}
    >
      <VStack maxW="460px" m="0 auto" mb="40px">
        <Heading as="h6" fontSize="18px">
          Transaction Summary
        </Heading>

        <Box mt="40px !important">
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`${toCoinLocale(state?.fromCoin ?? "btc")} Quantity`}
              description={toPrecision(+(state?.fromAmount ?? 0), state?.fromCoin ?? "btc", false) as string}
              borderRightColor={bcolor}
              borderBottomColor={bcolor}
            />
            <Item
              info={`${toCoinLocale(state?.toCoin ?? "btc")} Quantity`}
              description={toPrecision(+(state?.toAmount ?? 0), state?.toCoin ?? "btc", false) as string}
              borderBottomColor={bcolor}
            />
          </Grid>
          <Grid templateColumns="repeat(2, 1fr)">
            <Item
              info={`Final ${toCoinLocale(state?.toCoin ?? "btc")} Quantity`}
              description={toPrecision(finalQuantity, state?.toCoin ?? "btc", false) as string}
              borderRightColor={bcolor}
            />
            <Item
              info={`Fee ( ${configs.swap.fee}% )`}
              //  description={toPrecision(swapFee, state?.toCoin ?? "btc") as string}
              description={swapFeeToPrecision(state?.toCoin ?? "btc", swapFeeValue)}
            />
          </Grid>
        </Box>
      </VStack>

      <VStack mt="14px !important" px={{ base: "20px", "1sm": "46px" }} w="100%">
        <Button
          minW="unset"
          w="100%"
          onClick={handleSubmit}
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isSwaping}
          disabled={isSwaping}
        >
          Submit
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={onClose} disabled={isSwaping}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Item(props: ItemProps) {
  const { info, description, ...xprops } = props;

  const icolor = useColorModeValue("grey.400", "grey.100");
  const dcolor = useColorModeValue("grey.800", "white");

  return (
    <VStack border="1px solid transparent" p={{ base: "24px 12px", "1sm": "24px 40px" }} {...xprops}>
      <Text color={icolor} fontSize={{ base: "12px", "1sm": "14px" }} fontWeight="500">
        {info}
      </Text>
      <Text color={dcolor} fontSize={{ base: "16px", "1sm": "16px" }} fontWeight="500">
        {description}
      </Text>
    </VStack>
  );
}

export default function SwapPreview(props: SwapPreviewProps) {
  const { isOpen, onClose } = props;

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _mobileSuccessStyles={{
        borderRadius: { base: "30px 30px 0 0", "1sm": "20px" },
      }}
      _content={{
        borderRadius: { base: "0", "1sm": "20px" },
      }}
    >
      <Preview {...props} />
    </AbstractModal>
  );
}

function swapFeeToPrecision(coin: string, value: number) {
  const map: Record<string, number> = {
    [coin]: 5,
    btc: 8,
    eth: 8,
    tbtc: 8,
    teth: 8,
    usdt: 4,
    cusd: 4,
    usdc: 4,
    ceur: 4,
    celo: 4,
  };

  const precision = map[coin ?? "btc"];
  return toPrecision(value ?? 0, coin, true, precision, true) as string;
}
