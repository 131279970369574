import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Input,
  ListItem,
  ModalProps,
  Stack,
  Text,
  UnorderedList,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCreateCardProfileMutation, useGetCardConfigQuery, useGetLGAsQuery, useGetUserCardProfileQuery } from "apis";
import {
  AbstractModal,
  ConditionalRender,
  Icon,
  InputLabel,
  Link,
  Select,
  ShowCustomerSupport,
  useModalView,
  ViewSwitcherChildProps,
} from "components";
import { useAppConfig } from "contexts/appconfig.context";
import { capitalize, intersection, toLower } from "lodash";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { switchStyle, when } from "utils";
import { StepProps } from "./RequestVirtualCard";
import { useIDType, useSelector } from "hooks";
import { selectUser } from "store/slices";
import configs from "config";
import { IntercomProvider } from "react-use-intercom";

interface OptionalStepTwoProps extends StepProps, ViewSwitcherChildProps {}

// type ModalTypes = "agreement" | "creation-notice";
export default function OptionalStepTwo(props: OptionalStepTwoProps) {
  const { state, set, reset } = props;

  // const { isOpen, close, open } = useDisclosures<ModalTypes>();

  const { profile } = useSelector(selectUser);
  const { p5CardProviderIdTypes } = useIDType(profile!);

  const toast = useToast();
  const { getBlackbox } = useAppConfig();
  const { data: config } = useGetCardConfigQuery();
  console.log("Card Config", config);

  // const fundFee = 1.5; // in usd
  // const maintenanceFee = 0.42; // in usd

  const color = useColorModeValue("gray.600", "grey.100");

  const { hasNext, currentView } = useModalView();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleExtraData = useCallback(
    (newState: any) => {
      set({ extraData: { ...state?.extraData, ...newState } });
    },
    [state?.extraData, set]
  );

  const selected_provider = useMemo(() => {
    // const map: Record<NonNullable<typeof state.brand>, string> = {
    //   visa: "p4",
    //   mastercard: "p3",
    // };

    // return map[state?.brand ?? "mastercard"];
    return state?.cardProvider ?? "p3";
  }, [state]);

  const [createProfile, { isLoading: isCreatingProfile }] = useCreateCardProfileMutation();
  const { data: cardProfile, refetch, isLoading } = useGetUserCardProfileQuery(selected_provider);

  // console.log("Virtual Card Profile", cardProfile);

  const { data: lgaData, isLoading: isLGALoading } = useGetLGAsQuery(state?.extraData?.state, { skip: !state?.extraData?.state });

  console.log({ lgaData });

  const userState = useMemo(() => cardProfile?.data?.state, [cardProfile]);

  const lgas = useMemo(() => lgaData?.lgas ?? [], [lgaData]);

  const extraData = useMemo(() => state?.extraData ?? {}, [state]);

  const knownExtraFields = useMemo(
    () => [
      "bvn",
      "dob",
      "lga",
      "state",
      "address",
      "city",
      "nin",
      "idtype",
      "idnumber",
      "postalcode",
      "occupation",
      "annualsalary",
      "expectedmonthlyvolume",
    ], // should be in lowercase
    []
  );

  const missingFields = useMemo(
    () => ((cardProfile?.missingFields as string[]) ?? []).filter((field) => knownExtraFields.includes(toLower(field))),
    [cardProfile, knownExtraFields]
  );

  const isBvnValid = useMemo(() => {
    if (!(missingFields ?? []).includes("bvn")) return true;
    else return !!extraData?.bvn && extraData?.bvn?.length === 11;
  }, [extraData, missingFields]);

  console.log({ cardProfile });

  const transformExtraData = (extraData: typeof state.extraData) => {
    const annual_salary = when(!!extraData?.annualSalary, +(extraData?.annualSalary ?? 0), undefined);
    return { ...extraData, annualSalary: annual_salary };
  };

  const handleClick = async () => {
    const reqData = {
      extraData: transformExtraData(state?.extraData ?? {}),
      provider: state?.cardProvider,
    };

    const result = await createProfile(reqData).unwrap();
    if (!!result) {
      refetch();
      // don't reset card provider state so we can make the appropriate requests in the next step
      reset(["cardProvider", "brand", "cardValue"], "pick");
      toast({
        position: "bottom-right",
        title: "Success",
        description: `Card profile has been created successfully`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const needed_input = useMemo(
    () => missingFields.filter((mf) => !(state?.extraData ?? ({} as any))[mf]),
    [missingFields, state?.extraData]
  );

  const isDisabled = useMemo(() => {
    // const needed_input = missingFields.filter((mf) => !(state?.extraData ?? ({} as any))[mf]);
    // console.log("Needed Input", needed_input);
    if (!hasNext || !isBvnValid || isLoading || needed_input.length > 0 || isCreatingProfile) return true;
    return false;
  }, [isBvnValid, hasNext, isLoading, isCreatingProfile, needed_input]);

  const extrafieldInputName = useCallback((name: string) => {
    const map: Record<string, { label: string; inputType: string; minLen?: number; pattern?: string }> = {
      [name]: { label: capitalize(name), inputType: "text" },
      lga: { label: "LGA (Local Govt. Area)", inputType: "text", minLen: 2 },
      dob: { label: "DOB (Date Of Birth)", inputType: "date" },
      state: { label: "State", inputType: "text", pattern: "[a-zA-Z][a-zA-Z ]+[a-zA-Z]$" },
      postalCode: { label: "Postal Code", inputType: "text" },
      address: { label: "Address", inputType: "text", minLen: 5 },
      idNumber: { label: "ID Number", inputType: "number", minLen: 8 },
      bvn: { label: "BVN (Biometric Verification Number)", inputType: "number", minLen: 11 },
      nin: { label: "NIN (National Identity Number)", inputType: "number", minLen: 11 },
      occupation: { label: "Occupation", inputType: "text", pattern: "[a-zA-Z][a-zA-Z ]+[a-zA-Z]$" },
      annualSalary: { label: "Annual Salary (USD)", inputType: "number" },
      expectedMonthlyVolume: { label: "Expected Monthly Volume (USD)", inputType: "number" },
      // city: {label: "City", inputType: "text"},
    };
    return map[name];
  }, []);

  const has_known_fields = useMemo(() => {
    const intersections = intersection(knownExtraFields, cardProfile?.missingFields ?? []);
    return intersections.length > 0;
  }, [knownExtraFields, cardProfile?.missingFields]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleClick();
    // handleClick();
  };

  const toggleMissingFieldsDialog = () => {
    if (!isDisabled && missingFields.length < 1) return;
    if (!isOpen) return onOpen();
    return onClose();
  };

  useEffect(() => {
    const missing_fields = (cardProfile?.missingFields ?? []) as string[];
    if (!!missing_fields && missing_fields.includes("iovationBlackbox") && !state?.extraData?.iovationBlackbox) {
      const blackbox = getBlackbox();
      if (!!blackbox) handleExtraData({ iovationBlackbox: blackbox });
    }
  }, [cardProfile?.missingFields, state?.extraData?.iovationBlackbox, getBlackbox, handleExtraData]);

  return (
    <Box as="form" py="40px" px="8px" onSubmit={handleSubmit}>
      <HStack>
        <Heading as="h6" fontSize="md" fontWeight="600" color={color}>
          Step {currentView + 1}
        </Heading>
        <Text ml="28px !important" fontSize="14px" fontWeight="500">
          Create card profile
        </Text>
      </HStack>

      <Stack mt="52px">
        <ConditionalRender shouldRender={has_known_fields}>
          <Stack mb={when(missingFields?.length > 0, "24px !important", "0")}>
            {(missingFields ?? []).map((field: any) => {
              const field_opts = extrafieldInputName(field);
              return (
                <Fragment key={field}>
                  {field === "idType" && field === "idNumber" && (
                    <>
                      <FormControl mb="32px !important">
                        <InputLabel htmlFor="docType">ID Type</InputLabel>
                        <Select
                          placeholder="Select ID Type"
                          id="idType"
                          name="idType"
                          value={extraData["idType"] ?? ""}
                          onChange={(e) => handleExtraData({ idType: e.target.value })}
                        >
                          {p5CardProviderIdTypes?.map(({ name, value }) => (
                            <option value={value} key={value}>
                              {name}
                            </option>
                          ))}
                        </Select>

                        {extraData?.idType === "international_passport" && (
                          <Text display="block" mt="8px" as="span">
                            Note: ID should be at least 6 months from expiration
                          </Text>
                        )}
                      </FormControl>

                      <FormControl mb="32px !important">
                        <InputLabel htmlFor="docType">ID Number</InputLabel>
                        <Input
                          isRequired
                          type="number"
                          pattern={extrafieldInputName("idNumber")?.pattern}
                          minLength={extrafieldInputName("idNumber")?.minLen}
                          placeholder={`Enter ID Number`}
                          value={extraData["idNumber"] ?? ""}
                          onChange={(e) => handleExtraData({ idNumber: String(e.target.value).trim() })}
                        />

                        {extraData?.idType === "international_passport" && (
                          <Text display="block" mt="8px" as="span">
                            Note: ID should be at least 6 months from expiration
                          </Text>
                        )}
                      </FormControl>
                    </>
                  )}

                  {!["lga", "idType", "idNumber"].includes(field) && (
                    <FormControl mt="20px !important">
                      <InputLabel>{field_opts?.label}</InputLabel>
                      <Input
                        isRequired
                        pattern={field_opts?.pattern}
                        type={field_opts?.inputType}
                        minLength={field_opts?.minLen}
                        placeholder={`Enter ${field_opts?.label}`}
                        value={(extraData as any)[field] ?? ""}
                        onChange={(e) => handleExtraData({ [field]: e.target.value })}
                      />

                      {!!extraData?.bvn && (extraData?.bvn?.length < 11 || extraData?.bvn?.length > 11) && field === "bvn" && (
                        <FormHelperText fontSize="sm" color="red.400">
                          BVN is not valid
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}

                  {field === "lga" && (
                    <>
                      <FormControl mt="20px !important">
                        <InputLabel>State</InputLabel>
                        <Select
                          placeholder="Select state"
                          value={extraData["state"] ?? ""}
                          onChange={(e) => handleExtraData({ state: e.target.value })}
                        >
                          {!!userState && (
                            <option key={`state-1`} value={String(userState).toLowerCase()}>
                              {userState}
                            </option>
                          )}
                        </Select>
                      </FormControl>

                      <FormControl mt="20px !important" mb="24px !important">
                        <InputLabel isLoading={isLGALoading}>LGA</InputLabel>
                        <Select
                          placeholder="Select LGA"
                          value={(extraData as any)[field] ?? ""}
                          onChange={(e) => handleExtraData({ [field]: e.target.value })}
                        >
                          {lgas?.map((lga: string, i: number) => (
                            <option key={`lga-${i}`} value={String(lga).toLowerCase()}>
                              {lga}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Fragment>
              );
            })}

            <VStack py="52px">
              <Button
                fontSize="16px"
                fontFamily="var(--bitmama-fonts-heading)"
                type="submit"
                isLoading={isCreatingProfile}
                disabled={isDisabled}
                // onMouseEnter={toggleMissingFieldsDialog}
                onMouseLeave={toggleMissingFieldsDialog}
              >
                Create Profile
              </Button>
            </VStack>
          </Stack>
        </ConditionalRender>

        <ConditionalRender shouldRender={!has_known_fields && cardProfile?.status === ""}>
          <IntercomProvider appId={configs.INTERCOM_TOKEN}>
            <VStack
              w="100%"
              p="24px"
              borderRadius="8px"
              border="1px solid"
              borderColor="grey.200"
              maxW="560px"
              mb="20px !important"
            >
              <Icon type="infoError" boxSize="44px" color="#ebbe3a" />
              {/* <Heading mt="24px !important" mb="10px !important" as="h4" fontSize="24px">
            Feature not available
          </Heading> */}
              <Heading mt="20px !important" mb="10px !important" as="h4" fontSize="24px" textAlign="center">
                We're unable to create your card profile
              </Heading>
              <Text fontSize="20px" fontWeight="500" textAlign="center">
                It seems we're missing some required information. Please resubmit your KYC document to proceed.
              </Text>

              <VStack my="20px !important">
                {/* <Text fontSize="14px" fontWeight="500">
                  Missing information
                </Text>
                <HStack>
                  {(cardProfile?.missingFields ?? []).map((field: any) => (
                    <Badge size="sm" key={field}>
                      {field}
                    </Badge>
                  ))}
                </HStack> */}
                {/* {(cardProfile?.missingFields ?? []).includes("idType") && ( */}
                <HStack>
                  <Icon type="infoRounded" boxSize="14px" color="#ebbe3a" />
                  <Text fontSize="14px" fontWeight="500">
                    The card provider accepts only National ID and International Passport
                  </Text>
                </HStack>
                {/* )} */}
              </VStack>

              <Link mt="20px !important" color="secondary.400" to="/account/verification">
                Resubmit KYC
              </Link>
            </VStack>

            <ShowCustomerSupport />
          </IntercomProvider>
        </ConditionalRender>

        <ConditionalRender shouldRender={cardProfile?.status === "pending"}>
          <VStack w="100%" p="24px" borderRadius="8px" border="1px solid" borderColor="grey.200" maxW="520px" mb="20px !important">
            <Icon type="infoRounded" boxSize="44px" color="#ebbe3a" />
            {/* <Heading mt="24px !important" mb="10px !important" as="h4" fontSize="24px">
            Feature not available
          </Heading> */}
            <Heading mt="20px !important" mb="10px !important" as="h4" fontSize="24px" textAlign="center">
              Your card profile is awaiting approval.
            </Heading>
            <Text fontSize="20px" fontWeight="500" textAlign="center">
              {when(
                !!cardProfile?.verificationLink,
                "Click the button below to provide additional information to complete your profile",
                "Please check back later"
              )}
            </Text>
          </VStack>
        </ConditionalRender>

        <ConditionalRender shouldRender={cardProfile?.status === "declined"}>
          <VStack w="100%" p="24px" borderRadius="8px" border="1px solid" borderColor="grey.200" maxW="560px" mb="20px !important">
            <Icon type="infoErrorRounded" boxSize="44px" />
            {/* <Heading mt="24px !important" mb="10px !important" as="h4" fontSize="24px">
            Feature not available
          </Heading> */}
            <Heading mt="20px !important" mb="10px !important" as="h4" fontSize="24px" textAlign="center">
              Your virtual card profile wasn't approved
            </Heading>
            <Text fontSize="20px" fontWeight="500" textAlign="center">
              {when(
                !!cardProfile?.verificationLink,
                "Click the button below to provide additional information to complete your profile",
                "You can try reapplying in the future."
              )}
            </Text>
          </VStack>
        </ConditionalRender>

        <ConditionalRender shouldRender={!!cardProfile?.verificationLink}>
          <VStack py="52px">
            <Button
              fontSize="16px"
              fontFamily="var(--bitmama-fonts-heading)"
              isLoading={isCreatingProfile}
              disabled={isDisabled}
              onClick={() => window.open(cardProfile.verificationLink, "_blank")}
            >
              Verify Profile
            </Button>
          </VStack>
        </ConditionalRender>
      </Stack>

      <ConditionalRender shouldRender={needed_input?.length > 0}>
        <ListMissingFields missingFields={needed_input} getInfo={extrafieldInputName} onClose={onClose} isOpen={isOpen} />
      </ConditionalRender>
    </Box>
  );
}

function ListMissingFields(props: Omit<ModalProps, "children"> & { missingFields: string[]; getInfo: (name: string) => any }) {
  const { missingFields, getInfo, ...xprops } = props;
  const { colorMode } = useColorMode();

  return (
    <AbstractModal _content={{ maxW: "506px" }} {...xprops}>
      <VStack px="60px" py="40px">
        <Icon type="infoErrorRounded" boxSize="30px" />

        <VStack my="24px !important" color={switchStyle(colorMode, { dark: "white", light: "black" })}>
          <Heading as="h4" fontSize="24px" color="inherit">
            Oops!
          </Heading>
          <Text
            mt="10px !important"
            textAlign="center"
            fontFamily="var(--bitmama-fonts-heading)"
            fontWeight={500}
            color="inherit"
            lineHeight="28px"
          >
            Kindly fill in the missing fields below to proceed.
          </Text>
        </VStack>

        <Box px="10px" w="100%" alignItems="flex-start">
          <UnorderedList>
            {missingFields.map((field: any) => (
              <ListItem key={field}>{getInfo(field)?.label ?? field ?? "Unknown Field"}</ListItem>
            ))}
          </UnorderedList>
        </Box>

        <Button mt="42px !important" onClick={props.onClose}>
          Close
        </Button>
      </VStack>
    </AbstractModal>
  );
}
