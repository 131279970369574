import { Box, BoxProps, Button, Input, InputGroup, InputProps, InputRightElement, SelectProps } from "@chakra-ui/react";
import { RadixSelect } from "components/Select/RadixSelect";
import { Select } from "components/Select/Select";
import { PropsWithChildren, useMemo } from "react";
// import Select from "components/Select/Select";
import useMeasure from "react-use-measure";
import { when } from "utils";

interface CryptoTxProps extends InputProps {
  onMax?: () => void;
  isLoading?: boolean;
  coinValue?: string;
  onCoinChange?: (coin: string) => void;
  _rightEl?: BoxProps;
  _select?: SelectProps;
}

interface CSelectProps extends SelectProps {}

// @ts-ignore
// eslint-disable-next-line
function CSelect(props: PropsWithChildren<CSelectProps>) {
  const { children, ...xprops } = props;
  return (
    <Select
      minH="36px"
      maxH="36px"
      maxW="103px"
      iconSize="16px"
      fontSize={{ base: "12px", "2sm": "16px" }}
      sx={{
        select: {
          // paddingInlineStart: "12px",
          // paddingInlineEnd: "1.2rem",
        },
      }}
      {...xprops}
    >
      {children}
    </Select>
  );
}

export default function CrytpoTxInput(props: PropsWithChildren<CryptoTxProps>) {
  const { children, coinValue, isLoading, onMax, onCoinChange, _rightEl, _select, ...xprops } = props;

  // const spr = when(!!children, "180px", "48px");
  const mpr = when(!!onMax, "66px", "48px");
  // const pr = when(!!onMax && !!children, spr, mpr);

  // const mr = when(!!children, "118px", "18px");

  const [ref] = useMeasure({ scroll: false });
  const [rightElRef, rightElBounds] = useMeasure({ scroll: false });

  // const handleCoinChange = (e: any) => {
  //   e.preventDefault();
  //   onCoinChange && onCoinChange(e.target.value);
  // };

  // @ts-ignore
  // eslint-disable-next-line
  const rightElProps = useMemo(() => rightElBounds, [rightElBounds]);

  const margin = 12;
  const max_button_w = 48;
  const rightElSpacing = useMemo(() => rightElBounds.width + margin, [rightElBounds]);

  // max button padding right value
  const max_mr = useMemo(
    () => when(!!children && !!onMax, `${rightElSpacing + margin / 2}px`, "18px"),
    [rightElSpacing, children, onMax]
  );

  // input element padding right value
  const input_pr = when(!!onMax && !!children, `calc(${max_mr} + ${max_button_w + margin / 3}px)`, mpr);

  console.log("rightElBounds", max_mr);

  return (
    <Box pos="relative" ref={ref}>
      <InputGroup>
        {/* <InputLeftElement h="100%" left="0px" children={<CoinIcon coin="ngn" boxSize="24px" />} {..._leftEl} /> */}

        <Input pr={input_pr} w="100%" type="number" {...xprops} />

        {onMax && (
          <InputRightElement
            h="100%"
            w="fit-content"
            // right={max_mr}
            right={{ base: /* 99px */ max_mr, "2sm": max_mr }}
            children={
              <Button w={`${max_button_w}px`} variant="max" onClick={onMax} isLoading={isLoading} disabled={isLoading}>
                Max
              </Button>
            }
            {..._rightEl}
          />
        )}

        <InputRightElement
          ref={rightElRef}
          h="100%"
          // top="-2px"
          top="0px"
          w="fit-content"
          // visibility="hidden"
          // right="18px"
          right={`${margin}px`}
          children={
            // children && (
            //   <CSelect value={coinValue ?? ""} onChange={handleCoinChange} {..._select}>
            //     {children}
            //   </CSelect>
            // )

            children && (
              <RadixSelect
                use_coin_icon
                placeholder="Select"
                aria-label="buy crypto options"
                value={coinValue}
                onChange={onCoinChange}
                _trigger={{ minH: "36px", h: "36px", pl: "10px", borderRadius: "8px" }}
              >
                {children}
              </RadixSelect>
            )
          }
        />
      </InputGroup>
    </Box>
  );
}
