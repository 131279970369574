import { Box, Button, FormControl, FormHelperText, HStack, Select, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useGetMinimumWithdrawalAmountQuery, useWithdrawMutation } from "apis";
import {
  AbstractModal,
  AmountInput,
  BackButton,
  FormInfo,
  Icon,
  InputLabel,
  Link,
  ModalView,
  useAbstractModal,
  useModalView,
  ViewSwitcher,
  ViewSwitcherChildProps,
} from "components";
import { useBanks, usePartialState, useWalletBalance } from "hooks";
import { AppAnalyticEvent } from "interfaces";
import { Emitter, transformFiatWithdrawalToEngagement } from "libs";
import { useEffect, useMemo } from "react";
import PinView from "ui/Common/PinView";
import { currencyFormat, isFiat, toPrecision, when } from "utils";

type ViaType = "bank" | "momo" | "flutterwave";

interface WithdrawFiatModalProps {
  isOpen: boolean;
  onClose: () => void;
  coin?: string;
}

interface IState {
  via: ViaType;
  amount: string;
  bank: string;
  pin: string;
  reason: string;
  currentView: string;
  // ui managed
  hasPrev: boolean;
  onClick: () => void;
}

export interface ViewProps extends ViewSwitcherChildProps {
  state: Partial<IState>;
  set: (update: Partial<IState>) => void;
  reset: () => void;
  coin: string;
}

function Withdraw(props: ViewProps) {
  const { state, set, coin } = props;
  const { onNext } = useModalView();
  const { onClose } = useAbstractModal();
  const { banks, isLoading } = useBanks();
  const { getBalance, isLoading: isLoadingBalance } = useWalletBalance();

  const accNullColor = useColorModeValue("grey.300", "grey.200");
  const accNullLinkColor = useColorModeValue("secondary.400", "secondary.400");

  const options = useMemo(() => {
    const map: Record<string, { name: string; value: IState["via"] }[]> = {
      ngn: [
        { name: "Bank Account", value: "bank" },
        // { name: "Mobile Money", value: "momo" },
      ],
      ghs: [{ name: "Mobile Money", value: "momo" }],
      kes: [{ name: "MPESA", value: "momo" }],
      xof: [{ name: "Mobile Money", value: "momo" }],
      xaf: [{ name: "Mobile Money", value: "momo" }],
      cdf: [{ name: "Mobile Money", value: "momo" }],
      zar: [{ name: "Mobile Money", value: "momo" }],
    };

    return map[coin ?? "ngn"];
  }, [coin]);

  // const withdrawInfo = useMemo(() => {
  //   const map: Record<string, any> = {
  //     ghs: { minimum: 100, fee: 100 },
  //     ngn: { minimum: 500, fee: 200 },
  //     kes: { minimum: 500, fee: 200 },
  //   };

  //   return map[coin ?? "ngn"];
  // }, [coin]);

  const { data: withdrawConfig, isLoading: isWithdrawConfLoading } = useGetMinimumWithdrawalAmountQuery({ coin });
  const min = useMemo(() => (!!withdrawConfig ? withdrawConfig.min : 0), [withdrawConfig]);
  const fee = useMemo(() => (!!withdrawConfig ? withdrawConfig?.fee : 0), [withdrawConfig]);

  const accounts = useMemo(
    () => (banks ?? []).filter((b) => (state?.via === "bank" ? b?.type !== "mobilemoney" : b?.type === "mobilemoney")),
    [state?.via, banks]
  );

  const balance = useMemo(() => getBalance(coin as any) ?? 0, [getBalance, coin]);

  const renderBalance = useMemo(() => {
    if (isFiat(coin) && balance) return currencyFormat(coin as any).format(balance);
    if (!isFiat(coin) && balance) return toPrecision(balance, coin);
    return toPrecision(0, coin);
  }, [balance, coin]);

  const renderFee = useMemo(() => {
    if (isFiat(coin) && fee) return currencyFormat(coin as any).format(fee);
    if (!isFiat(coin) && fee) return toPrecision(fee ?? 0, coin);
    return toPrecision(0, coin);
  }, [fee, coin]);

  const isInsufficientBalance = useMemo(() => !!state?.amount && +state?.amount > balance, [state?.amount, balance]);

  const isLessThanMinimum = useMemo(() => !!(state?.amount && +state?.amount < min), [state, min]);

  const isDisabled = useMemo(
    () => !(state?.amount && state?.bank) || isLoading || isLoadingBalance || isInsufficientBalance || isLessThanMinimum,
    [state, isInsufficientBalance, isLoading, isLoadingBalance, isLessThanMinimum]
  );

  // manage modal back button
  // hide back button
  useEffect(() => {
    if (state?.hasPrev) {
      set({ hasPrev: false, onClick: () => {} });
    }

    // eslint-disable-next-line
  }, []);

  const addAccountNumberLink = useMemo(() => {
    const map: Record<ViaType, string> = {
      bank: "/account/banks",
      momo: "/account/momo",
      flutterwave: "",
    };

    return map[state?.via!];
  }, [state?.via]);

  return (
    <VStack p={{ base: "0px 20px", "1sm": "48px 34px", "3sm": "68px" }}>
      <FormControl minH={{ base: "56px", "1sm": "60px" }}>
        <InputLabel htmlFor="withdraw-via">Withdraw Via</InputLabel>
        <Select
          id="withdraw-via"
          placeholder="Choose option"
          value={state?.via ?? ""}
          onChange={(e) => set({ via: e.target.value as IState["via"] })}
        >
          {options.map((opt, index: number) => (
            <option key={`withdraw-option-${index}`} value={opt.value}>
              {opt.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl minH={{ base: "56px", "1sm": "60px" }} mt={{ base: "16px !important", "2sm": "42px !important" }}>
        <InputLabel isLoading={isLoadingBalance || isWithdrawConfLoading} htmlFor="withdraw-amount">
          Amount
        </InputLabel>
        <AmountInput
          isRequired
          isInvalid={isInsufficientBalance}
          id="withdraw-amount"
          value={state?.amount ?? ""}
          onMax={() => set({ amount: Number(balance).toFixed(2) })}
          onChange={(e) => set({ amount: e.target.value })}
        />

        {!!balance && !isNaN(balance) && (
          <FormInfo isInvalid={isInsufficientBalance} mt="10px" info="Available Balance:" description={`${renderBalance}`} />
        )}
        {isInsufficientBalance && (
          <FormInfo
            isInvalid={isInsufficientBalance}
            mt="10px"
            info=""
            description={`Insufficient Balance`}
            _description={{ ml: "0 !important" }}
          />
        )}
      </FormControl>

      {state?.via && (
        <FormControl minH={{ base: "56px", "1sm": "60px" }} mt={{ base: "16px !important", "2sm": "42px !important" }}>
          <InputLabel isLoading={isLoading} htmlFor="withdraw-account">
            {when(state.via === "bank", "Select Account", "Select Account")}
          </InputLabel>
          <Select
            id="withdraw-account"
            placeholder="Select account"
            value={state?.bank ?? ""}
            onChange={(e) => set({ bank: e.target.value })}
          >
            {(accounts ?? []).map((acc: any) => (
              <option key={`bank-${acc?._id}`} value={acc?._id}>
                {acc?.bankName} - {acc?.bankAccountNumber}
              </option>
            ))}

            {accounts?.length < 1 && <option disabled>You haven't added a {state?.via} account.</option>}
          </Select>
        </FormControl>
      )}

      {state?.via && (
        <FormControl minH={{ base: "56px", "1sm": "60px" }}>
          {accounts?.length < 1 && (
            <FormHelperText fontWeight="500">
              <HStack>
                <Text color={accNullColor}>You haven't added a {state?.via} account. Add it</Text>
                <Link ml="3px !important" to={addAccountNumberLink} color={accNullLinkColor} fontWeight="500" onClick={onClose}>
                  here
                </Link>
              </HStack>
            </FormHelperText>
          )}

          {accounts?.length > 0 && (
            <FormInfo
              mt={{ base: "7px", "2sm": "initial" }}
              leftElement={<Icon type="sale" color="secondary.300" boxSize="28px" />}
              info="Transaction fee:"
              description={`${renderFee}`}
              _description={{ color: "unset" }}
            />
          )}

          <HStack
            alignItems="flex-start"
            fontSize={{ base: "12px", "2sm": "14px" }}
            fontWeight="500"
            mt={{ base: "23px", "2sm": "38px" }}
          >
            <Box as="strong" lineHeight={1.25}>
              NOTE:
            </Box>
            <Box fontSize={{ base: "12px", "1sm": "16px" }}>
              <FormHelperText mt="0" color={when(isLessThanMinimum, "red.400", "gray.500")}>
                The minimum amount you can withdraw is {currencyFormat(coin as any).format(min)}
              </FormHelperText>

              {/* <FormHelperText color="gray.500" mt="0">
                Withdrawal request might take 6hrs or less to be processed
              </FormHelperText> */}
            </Box>
          </HStack>
        </FormControl>
      )}

      <VStack px={{ base: "0 !important", "2sm": "46px" }} mt={{ base: "25px !important", "2sm": "100px !important" }}>
        <Button
          onClick={() => {
            onNext();
          }}
          fontFamily="var(--bitmama-fonts-heading)"
          disabled={isDisabled}
          minW={{ base: "100%", smx: "330px", "4sm": "400px" }}
        >
          Submit
        </Button>
      </VStack>
    </VStack>
  );
}

function TransactionPin(props: ViewProps) {
  const { state, set, reset, coin } = props;
  const { hasPrev, onPrev, currentView: currentViewCount } = useModalView();

  const { onClose, updateModal } = useAbstractModal();

  const [withdraw, { data: result, isLoading: isWithdrawing, isSuccess, isError, reset: resetApiState }] = useWithdrawMutation();

  const { data: withdrawConfig } = useGetMinimumWithdrawalAmountQuery({ coin });
  const fee = useMemo(() => (!!withdrawConfig ? withdrawConfig?.fee : 0), [withdrawConfig]);

  const handleClose = () => {
    onClose();
    reset && reset();
  };

  const fiatPaymentMethod = useMemo(() => {
    const map: Record<string, "bank" | "mobilemoney" | "flutterwave"> = {
      bank: "bank",
      momo: "mobilemoney",
      flutterwave: "flutterwave",
    };

    return map[state?.via ?? "bank"];
  }, [state?.via]);

  const makeWithdrawal = async (pin?: string) => {
    const reqData = {
      amount: state?.amount!,
      bankId: state?.bank!,
      currency: coin,
      fiatPaymentMethod,
      pin: pin ?? state?.pin!,
      reason: state?.reason ?? "",
    };

    Emitter.emit(AppAnalyticEvent.WITHDRAWAL_INITIATED, transformFiatWithdrawalToEngagement(reqData));

    const result = await withdraw(reqData).unwrap();
    if (!!result) Emitter.emit(AppAnalyticEvent.WITHDRAWAL_SUBMITTED, transformFiatWithdrawalToEngagement(reqData));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await makeWithdrawal();
  };

  const isDisabled = useMemo(
    () => Boolean(!state?.pin || (state?.pin && state?.pin?.length < 6) || isWithdrawing),
    [state, isWithdrawing]
  );

  const actualAmountAfterFee = useMemo(() => {
    if (result?.withdrawal?.deductedAmount) return Number(result?.withdrawal?.deductedAmount);
    return +(state?.amount ?? 0) - fee;
  }, [state?.amount, fee, result]);

  // manage modal back button
  // show back button
  useEffect(() => {
    if (!state?.hasPrev) {
      set({ hasPrev, onClick: onPrev });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess && !isError) {
      updateModal &&
        updateModal({
          isSuccess: true,

          success: {
            description: `${currencyFormat(coin as any).format(actualAmountAfterFee)} will be deposited into your ${when(
              state?.via === "bank",
              "bank",
              "mobile money"
            )} account.`,
          },
        });

      reset();
    }

    if (currentViewCount === 0 && !isError) {
      set({ currentView: "default_view" });
    }

    if (isSuccess) {
      set({ currentView: "success_modal" });
    }

    return () => {
      resetApiState();
    };
  }, [
    isSuccess,
    isError,
    reset,
    resetApiState,
    state?.amount,
    updateModal,
    state.pin,
    currentViewCount,
    set,
    state.hasPrev,
    hasPrev,
    coin,
    actualAmountAfterFee,
    state?.via,
  ]);

  console.log("withdrawal result", result);

  return (
    <VStack p={{ base: "34px 20px", "1sm": "48px 50px", "3sm": "45px 100px" }} as="form" onSubmit={handleSubmit}>
      <PinView onPin={(pin) => set({ pin })} on_close={onClose} on_complete={async (pin) => await makeWithdrawal(pin)} />

      <VStack mt="56px !important" px="4px" w="100%">
        <Button
          minW="unset"
          w="100%"
          type="submit"
          fontFamily="var(--bitmama-fonts-heading)"
          isLoading={isWithdrawing}
          disabled={isDisabled}
        >
          Submit
        </Button>
        <Button variant="transparent" textDecoration="underline" minW="unset" w="100%" onClick={handleClose}>
          Cancel
        </Button>
      </VStack>
    </VStack>
  );
}

function Views(props: ViewProps) {
  const { set } = props;
  const { currentView: currentViewCount } = useModalView();

  useEffect(() => {
    if (currentViewCount === 0) {
      set({ currentView: "default_view" });
    }
  }, [currentViewCount, set]);

  return (
    <ViewSwitcher>
      <Withdraw {...props} />
      <TransactionPin {...props} />
    </ViewSwitcher>
  );
}

export default function WithdrawFiatModal(props: WithdrawFiatModalProps) {
  const { isOpen, onClose, coin } = props;
  const [state, set, reset] = usePartialState<IState>({
    hasPrev: false,
    onClick: () => {},
    currentView: "default_view",
  });

  const viewCount = useMemo(() => state.currentView === "success_modal" && !state.hasPrev, [state.currentView, state.hasPrev]);
  const authView = useMemo(() => state.currentView === "default_view" && state.hasPrev, [state.currentView, state.hasPrev]);

  console.log(state.currentView, "current view");
  // console.log(, "current view");

  const handleClick = () => {
    state.onClick!();
    set({ currentView: "default_view" });
  };

  return (
    <AbstractModal
      isOpen={isOpen}
      onClose={onClose}
      _content={{
        maxWidth: { base: "425px", "1sm": "520px", "4sm": "640px" },
        minH: { base: viewCount ? "initial" : "100vh", "1sm": "initial" },
        height: { base: viewCount ? "385px" : "initial", "1sm": "initial" },
        borderRadius: { base: viewCount ? "30px 30px 0px 0px" : "0", "1sm": "20px" },
        pb: { base: viewCount ? "0" : "48px", "1sm": "0" },
        padding: "0",
        position: { base: viewCount ? "absolute" : "initial", "1sm": "initial" },
        bottom: { base: viewCount ? "0" : "initial", "1sm": "initial" },
        margin: { base: "0", "1sm": "initial" },
      }}
    >
      {(state?.hasPrev! || (state.currentView === "default_view" && !authView)) && (
        <BackButton
          display={{
            "1sm": state.currentView === "default_view" && !authView ? "none" : "block",
          }}
          onClick={state.currentView === "default_view" && !authView ? onClose : handleClick}
        />
      )}

      <ModalView>
        <Box
          mt={{ base: authView || (state.currentView === "default_view" && !authView) ? "48px" : "0px", "1sm": "0" }}
          padding={{ base: viewCount ? "10px 20px" : "20px", "1sm": "0" }}
          cursor="pointer"
          display={{ base: "block", "1sm": "none" }}
        >
          <Text color="brand.black" fontSize="18px" fontWeight="600">
            {viewCount ? "" : "Withdraw from wallet"}
          </Text>
        </Box>

        <Views {...{ state, set, reset, coin: coin ?? "ngn" }} />
      </ModalView>
    </AbstractModal>
  );
}
