import { Box, Button, Grid, HStack, Stack, useColorMode, useColorModeValue, VStack } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { ConditionalRender, CreditCard, Icon, Select, Title, TitleBar } from "components";
import { useSidePage } from "contexts";
import { useAppConfig } from "contexts/appconfig.context";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { useDefaultStyle, useDisclosures } from "hooks";
import { CardRo } from "interfaces";
import { chunk, toLower, capitalize, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import CardAgreement from "ui/Cards/Modals/CardAgreement";
import FeatureUnavailableModal from "ui/Common/Modals/FeatureUnavailable";
import { switchStyle, when, wrap } from "utils";

interface CardsViewProps {
  cards: CardRo[];
  isFetching: boolean;
  isLoading?: boolean;
  setQueries: (update: any) => void;
  totalCount: number;
}

interface CardsViewControllerProps {
  currentPage: number;
  totalPages: number;
  onNext?: () => void;
  onPrev?: () => void;
  onIndicatorClick: (index: number) => void;
}

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

type ModalTypes = "agreement" | "featureUnavailable";

export default function CardsView(props: CardsViewProps) {
  const { cards, setQueries, isFetching, isLoading, totalCount } = props;
  const { colorMode } = useColorMode();

  const { appfigs, its_christmas } = useAppConfig();
  const [filter, setFilter] = useState("active");

  const { cardConfig } = appfigs ?? {};

  const { onOpen } = useSidePage();
  const { isOpen, open, close } = useDisclosures<ModalTypes>();
  const linkColor = useColorModeValue("primary.800", "secondary.400");

  const isCreateCardDisabled = useMemo(() => cardConfig?.disableCardCreate ?? false, [cardConfig]);

  const [[page, direction], setPage] = useState([0, 0]);

  const totalPages = useMemo(() => Math.ceil(cards.length / 4), [cards]);

  const cardIndex = wrap(0, totalPages, page);

  const paginate = (newDirection: number) => {
    const newPage = page + newDirection;
    setPage([newPage, newDirection]);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
    if (filter === "active") setQueries({ active: true });
    if (filter === "all") setQueries({ active: false, disabled: false });
  };

  const renderCards = useMemo(() => {
    const click_handler = (card: CardRo) => {
      if (!["active", "frozen"].includes(toLower(card?.status))) return;
      onOpen({ key: "VirtualCardDetails", data: { card }, params: { cardId: card?.cardId, provider: card?.provider } });
    };

    const brand = (card: CardRo) => card?.brand ?? when(["p4", "p5"].includes(card?.provider), "visa", "mastercard");

    const layouts = chunk(cards, 4).map((cards, i) => (
      <Grid key={`card-list-layout-${i}`} templateColumns={{ sm: "1fr", "3sm": "repeat(2, 1fr)", md: "repeat(2, 1fr)" }} gap="30px">
        {cards.map((card, i) => (
          <CreditCard
            key={`virtual-card-${i}`}
            type={(card?.cardType?.type as any) ?? "black"}
            cardType={card?.cardType}
            name={card?.userDetails?.fullName}
            validity={card?.expiry}
            number={card?.cardNumber}
            status={card?.status}
            brand={brand(card)}
            its_christmas={its_christmas}
            // onClick={() => click_handler(card)}
            onClick={card?.brand?.toLowerCase() === "mastercard" ? () => {} : () => click_handler(card)}
            label={card?.label}
          />
        ))}
      </Grid>
    ));

    return layouts[cardIndex];
  }, [cards, cardIndex, onOpen, its_christmas]);

  const handleIndicatorClick = (newPage: number) => {
    const newDirection = newPage > page ? 1 : -1;
    setPage([newPage, newDirection]);

    // newPage is zero (0) indexed, add 1 to make it count from 1;
    // const pageIndex = wrap(0, Math.ceil(cards.length / 4), newPage);
  };

  const handleCreateCard = () => {
    if (isCreateCardDisabled) open("featureUnavailable")();
    else navigate("/cards/request");
  };

  return (
    <AnimatePresence initial={false} custom={direction}>
      <Box my="20px !important" w="100%">
        <ConditionalRender shouldRender={!isLoading}>
          <Stack mb="34px" w="100%">
            <HStack justifyContent="space-between">
              <TitleBar>
                <Title
                  fontSize="16px"
                  isLoading={isFetching}
                  color={switchStyle(colorMode, { dark: "secondary.400", light: "primary.default" })}
                >
                  Your cards ({(cards ?? []).length})
                </Title>
              </TitleBar>

              <CardsViewController
                currentPage={cardIndex}
                totalPages={totalPages}
                onNext={() => paginate(1)}
                onPrev={() => paginate(-1)}
                onIndicatorClick={handleIndicatorClick}
              />
            </HStack>

            <Select maxW="200px" value={filter ?? ""} onChange={(e) => handleFilter(e.target.value)}>
              {["all", "active"].map((value) => (
                <option value={value} key={`status-${value}`}>
                  {capitalize(value)}
                </option>
              ))}
            </Select>
          </Stack>
        </ConditionalRender>

        {!isLoading && !isEmpty(cards) && (
          <Box>
            <motion.div
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            >
              {renderCards}
            </motion.div>

            <VStack mt="62px">
              <ConditionalRender shouldRender={totalCount < +(cardConfig?.maxCardHoldingPerUser ?? 8)}>
                <Button fontFamily="var(--bitmama-fonts-heading)" onClick={handleCreateCard}>
                  Create a New Card
                </Button>
              </ConditionalRender>
              <Button
                variant="link"
                mt="30px !important"
                textDecoration="underline"
                fontWeight="700"
                fontSize="14px"
                color={linkColor}
                onClick={open("agreement")}
              >
                View Virtual Card Terms and Conditions
              </Button>
            </VStack>
          </Box>
        )}
      </Box>

      <CardAgreement isOpen={isOpen("agreement")} onClose={close("agreement")} />
      <FeatureUnavailableModal isOpen={isOpen("featureUnavailable")} onClose={close("featureUnavailable")} />
    </AnimatePresence>
  );
}

function CardsViewController(props: CardsViewControllerProps) {
  const { currentPage, totalPages, onPrev, onNext, onIndicatorClick } = props;

  console.log("Cards TotalPages", totalPages);

  const { shadow, borderColor } = useDefaultStyle();

  const isCurrent = (index: number) => index === currentPage;
  const w = (index: number) => when(!!isCurrent(index), "30px", "15px");
  const bg = (index: number) => when<any>(!!isCurrent(index), "linear-gradient(45deg, #072420, #31B7A982)", "#CFDAD9");
  const isDisabled = useMemo(() => totalPages < 2, [totalPages]);

  return (
    <HStack justifyContent="space-between">
      <Box mt="-4px !important" display="flex" gridGap="4px">
        {Array(totalPages)
          .fill(0)
          .map((_, i) => (
            <motion.div
              key={`card-controller-indicator-${i}`}
              style={{ background: "transparent" }}
              initial={{ width: "15px", transformOrigin: "left" }}
              animate={{ width: w(i), transformOrigin: "left" }}
              exit={{ width: "15px", transformOrigin: "left" }}
            >
              <Box as="button" w="100%" h="4px" borderRadius="50px" bg={bg(i)} onClick={() => onIndicatorClick(i)} />
            </motion.div>
          ))}
      </Box>

      <HStack>
        <Button
          p="0"
          h="fit-content"
          minW="fit-content"
          minH="fit-content"
          variant="unstyled"
          _focus={{ shadow, borderColor }}
          _active={{ shadow, borderColor, transform: "scale(0.9)", transition: "all .3s ease-in-out" }}
          onClick={onPrev}
          disabled={isDisabled}
        >
          <Icon type="circleLeftArrow" />
        </Button>
        <Button
          p="0"
          h="fit-content"
          minW="fit-content"
          minH="fit-content"
          variant="unstyled"
          _focus={{ shadow, borderColor }}
          _active={{ shadow, borderColor, transform: "scale(0.9)", transition: "all .3s ease-in-out" }}
          onClick={onNext}
          disabled={isDisabled}
        >
          <Icon type="circleLeftArrow" transform="rotate(-180deg)" />
        </Button>
      </HStack>
    </HStack>
  );
}
